import React from 'react'

import BlogPosts from '../containers/BlogPosts';

import Layout from '../components/Layout'

const IndexPage = () => (
  <Layout>
    <BlogPosts />
  </Layout>
);

export default IndexPage
