import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import PostsMatrix from '../components/PostsMatrix';
import PostPreview from '../components/PostPreview';

const BlogPosts = () => {
  return (
    <StaticQuery
      query={graphql`
        query Homepage{
          posts: allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 6) {
            edges {
              node {
                relativePath
                frontmatter {
                  title
                  subtitle
                  destination
                }
                fields {
                  slug
                }
              }
            }
          }
          images: allFile(filter: { absolutePath: { regex: "/.*/title.jpg/" }}) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 225) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <PostsMatrix>
          {data.posts.edges.map(({ node }, index) => (
            <PostPreview
              key={`item-${index}`}
              node={node}
              images={data.images}
            />
          ))}
        </PostsMatrix>
      )}
    />
  );
};

export default BlogPosts;
